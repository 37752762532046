.calculator-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.calculator-buttons button, 
.calculator-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.15s ease;
  position: relative;
  transform: translateY(0);
}

/* Hover effect */
.calculator-buttons button:hover,
.calculator-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Click/Active effect */
.calculator-buttons button:active,
.calculator-buttons button.pressed,
.calculator-button:active,
.calculator-button.pressed {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #004085;
}

/* Focus effect for keyboard navigation */
.calculator-buttons button:focus,
.calculator-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Animation for keyboard press */
@keyframes buttonPress {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
    background-color: #004085;
  }
  100% {
    transform: scale(1);
  }
}

.calculator-buttons button.key-pressed,
.calculator-button.key-pressed {
  animation: buttonPress 0.2s ease;
}

/* Responsive styles */
@media (max-width: 767px) {
  .calculator-buttons button,
  .calculator-button {
    font-size: 0.85rem;
    padding: 0.4rem 0.8rem;
  }
  
  .calculator-buttons {
    gap: 0.5rem;
  }
} 