.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #002b36;
  color: #fdf6e3;
}

.ui.inverted.header {
  color: #fdf6e3 !important;
}

h1,h2,h3 {
  color: #fdf6e3;
}

.main {
  flex:1;
  margin-top: 9em;
  padding-left: 10vw;
  padding-right: 10vw;
}

.header-icon {
  text-align: center !important;
  vertical-align: center !important;
}

.header-text {

}

.nav-logo{
  padding-right: 4em;
  height : 5.5em
}
